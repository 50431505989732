/*
 * Date: 2023-07-17
 * Description: Loading token component!
 * Author: Philippe Leroux @ skitsc
 */

import { Box , CircularProgress , Typography } from '@mui/material'

interface i_loading_c {
	text : string
}
const LoadingFull = ({ text } : i_loading_c ) =>{
    return (
	<Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100vh"} >
		<Box>
			<CircularProgress sx={{ }} />
		</Box>
		<Box>
			<Typography ml={3}>{text} </Typography>
		</Box>
	</Box>
    )
}
export default LoadingFull