


/*
 * Date: 2025
 * Description: Img new component for uploads
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import { ImageListItemBar , IconButton , Box , Button , Typography} from '@mui/material';
import { useState , useEffect , useRef } from 'react';

//Icons
import CloseIcon from '@mui/icons-material/Close';

//Interfaces
import { i_file_uploads_props } from '../../interfaces/utility.interface';

//Middlewares
import { m_picture_validation } from '../../validation/utility.middleware';
import { clean_container_borders } from '../../styles/form.styles';

//Utils


const UploadBoxV2 = ( props : i_file_uploads_props ) =>{
    const [imgUrls, setImgUrls] = useState<string[]>([]);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const S = props
    const W = props.box_width
    const M = props.mobile
    useEffect(() => {
        const urls = props.uploads.map((item) =>
            item instanceof File ? URL.createObjectURL(item) : item
        );
        setImgUrls(urls);
        return () => {
        urls.forEach((url) => URL.revokeObjectURL(url));
        };
    }, [props.uploads]);
    const getFileNames = (files: File[]) : string =>{
        return files.map((file : File) => file.name).join(', ');
    }
    const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            const validFiles = Array.from(selectedFiles).filter(file => m_picture_validation(file , props.file_type));
            const invalidFiles = Array.from(selectedFiles).filter(file => !m_picture_validation(file, props.file_type));
            const total = validFiles.length + S.uploads.length + S.assets.length
            if(total <= S.max){
                if (validFiles.length > 0) {
                    const updatedUploads = [...S.uploads, ...validFiles];
                    S.setUploads(updatedUploads);
                    S.setEuploads('');
                } 
                if(invalidFiles.length > 0) {
                    var str = getFileNames(invalidFiles)
                    S.setEuploads("L'extension du fichié n'est pas valide veuillez utilisé un cité parmis la liste qui suit : (jpg, jpeg, png, ou gif). Le fichier : "+str+ " n'a pas été téléverser.");
                }
            }else{
                var valid : boolean = true;
                if(S.min !== undefined){
                    if(S.min === total) valid = false
                }
                if(valid)S.setApiError({ open : true, promise : { type : 'Failed' , message : "Veuillez selectionné seulement " + S.max + " photos maximum. "  , data : []} });
            }
        }
      };
    const handleBoxClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleCallBack = ( index : number ) => {
        const newArray = S.uploads.filter((_, i) => i !== index);
        S.setUploads(newArray);
        if (fileInputRef.current) {
            const dt = new DataTransfer();
            newArray.forEach((file) => dt.items.add(file));
            fileInputRef.current.files = dt.files;
        }
    }
    useEffect(() =>{
        if(fileInputRef.current !== null) fileInputRef.current.value = '';
    },[props.title])
    return (
        <Box>
            <Box sx={{ textAlign: 'center', marginTop: 1 }}>
                <Typography variant="h6">{props.title}</Typography>
            </Box>
            <Box sx={{ width: props.box_width, maxHeight: 600, display: 'grid', gridTemplateColumns: ( props.uploads.length === 1 || M === true ) ? '1fr' : 'repeat(2, 1fr)', gap: ( props.uploads.length === 1 || M === true )? 2 : 8, padding: 2, overflowY: 'auto', placeItems: ( props.uploads.length === 1 || M === true )? 'center' : 'start', }} >
                {props.uploads.map((item: File, index: number) => (
                    <Box key={index} sx={{ position: 'relative', width: props.uploads.length === 1 ? '100%' : 'auto', maxWidth: props.uploads.length === 1 ? '600px' : '100%', }} >
                    <img src={imgUrls[index]} alt={item.name} loading="lazy" style={{ width: '100%', height: 'auto', borderRadius: 8, }} />
                    <ImageListItemBar title={item.name} position="top" sx={{ background: 'rgba(0, 0, 0, 0.5)', color: 'white', top: 0, width: '100%', position: 'absolute', zIndex: 1, }}
                        actionIcon={ <IconButton sx={{ color: 'white', }} onClick={() => handleCallBack(index)} aria-label={`remove ${item.name}`} > <CloseIcon /> </IconButton> } />
                    </Box>
                ))}
            </Box>
            {props.Euploads !== '' &&
                <Box mt={8} mb={8} sx={clean_container_borders}>
                    <Typography sx={{ color : 'red' , textDecoration : 'underline' , textAlign : 'center' , padding : 2}}>{props.Euploads}</Typography>
                </Box>
            }
            <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={2}>
                <input type="file" ref={fileInputRef} onInput={handleFiles} multiple hidden accept="image/*" capture="environment" />
                    {props.uploads.length < props.max &&
                        <Button variant="contained" onClick={handleBoxClick}>
                            {props.language === 'fr' ? "Téléversé des fichiers" : "Upload files"}
                        </Button>
                    }
            </Box>
        </Box>
      );
      
}

export default UploadBoxV2