
//Modules
import { Box } from "@mui/material"
import { Link } from "react-router-dom";

const NotFound = ( ) => {
    return (
        <div style={{ textAlign: "center", marginTop: "15vh" }}>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <Box component={Link} to="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Access admin controls</Box>
            <Box sx={{ display : 'block' , marginTop : '5vh'}} component={Link} to="/">Go Home</Box>
        </div>
    )
}

export default NotFound