
/*
 * Date: 2025
 * Description: Component for signatures
 * Author: Philippe Leroux @ Skitsc
 */

import React, { useRef , forwardRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Box, Button } from '@mui/material';

//Interfaces
import { i_snack_alert } from '../../interfaces/utility.interface';
import { t_lang } from '../../types/types';
interface i_signature_props {
    onSave: (dataUrl: string) => void;
    onClear: () => void;
    sign: null | string;
    setApiError: (event: i_snack_alert) => void;
    mobile : boolean;
    language : t_lang
}
const SignaturePad = forwardRef<SignatureCanvas , i_signature_props>(({ onSave , onClear , sign , setApiError , mobile , language } ,ref) => {
    const save_btn = { en : 'Save' , fr : 'Enregistrer'}
    const retry_btn = { en : 'Retry' , fr : 'Réessayer'}
    const err_txt = { en : "Please sign before saving" , fr : "Veuillez signer avant d'enregistrer"}
    const handleClear = () => {
        if (ref && typeof ref !== 'function' && ref.current) {
            console.log('Clearing..')
            ref.current.clear();
        }
        onClear();
    };

    const handleSave = () => {
        if (ref && typeof ref !== 'function' && ref.current) {
            if (ref.current.isEmpty()) {
                setApiError({
                    open: true,
                    promise: {
                        data: '',
                        message: err_txt[language],
                        type: 'Error',
                    },
                });
                return;
            }
            const dataUrl = ref.current.getTrimmedCanvas().toDataURL('image/png');
            onSave(dataUrl);
        }
    };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
    <Box sx={{ position: 'relative', width: '100%', maxWidth: '600px', textAlign: 'center' }}>
        <Box sx={{ position: 'relative', height: mobile ? 230 : 400 , marginLeft : mobile ? -2 : 0 , marginRight : mobile ? -2 : 0}}>
            {sign !== null ? 
                <img src={sign} alt="Signature" style={{ maxWidth: '100%', height: 'auto' , marginTop : mobile ? 5 : 23 }} />
                :
                <SignatureCanvas ref={ref} penColor="black" canvasProps={{ height: 400, style: { border: '1px solid #000', borderRadius: 8, width: '100%', }, }} />
            }
        </Box>
        <Box sx={{ marginTop: mobile ? 2 : 5, display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button variant="contained" onClick={handleClear}>
                {retry_btn[language]}
            </Button>
            {sign === null &&
                <Button variant="contained" color="primary" onClick={handleSave} >
                {save_btn[language]}
                </Button>
            }
        </Box>
    </Box>
</Box>
  );
}
)

export default SignaturePad;