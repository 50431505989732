/*
 * Date: 2025
 * Description: New Claim form includes various steps
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { Box ,Grid, IconButton, MenuItem, TextField, Typography , Button, Tooltip , InputAdornment , Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material"
import { ReactElement , useState , ChangeEvent, useEffect , useRef } from "react"
import SignatureCanvas from 'react-signature-canvas';
import dayjs , { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider , DatePicker} from "@mui/x-date-pickers";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';
//Interfaces && types
import { i_initial_props , i_file_uploads_props, i_snack_alert } from "../interfaces/utility.interface"
import { i_alert_props } from "../interfaces/utility.interface";
import { i_complete_new_claim, i_customer_errors } from "../interfaces/customer.interface";

//Utils
import { f_fetch, f_fetch_multiform } from "../api/fetch";
import { f_empty_promise , formatPhoneNumber } from "../utils/utility";
import { empty_customer_errors, empty_new_claim } from "../utils/constant";

//Middleware
import { m_validate_email, m_validate_empty_fields, m_validate_opt_phone, m_validate_phone, m_validate_zip_code } from "../validation/utility.middleware";
import { m_validate_public_claim } from "../validation/main.middleware";

//Component
import AlertDialog from "../components/utility/alert";
import { clean_container_borders, full_width, input_item, input_item_sm, item_center, item_title } from "../styles/form.styles";
import UploadBoxV2 from "../components/utility/upload.box.v2";
import SignaturePad from "../components/utility/signature.box";

//Constant
import { provinces } from '../utils/constant';
import CircularUnderLoad from "../components/utility/center.loader";

const NewClaim = ( props : i_initial_props ) : ReactElement => {

    const [ api_errors , setApiError ] = useState<i_snack_alert>({ open : false, promise : f_empty_promise() })
    const [ step , setStep ] = useState<number>(1)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ sub_step , setSubStep ] = useState<number>(1)
    const [ otp_err , setOtpErr ] = useState<string>('')
    const [ Luploads , setLUploads ] = useState<any[]>([])
    const [ L2uploads , setL2Uploads ] = useState<any[]>([])
    const [ Auploads , setAUploads ] = useState<any[]>([])
    const [ Ruploads , setRUploads ] = useState<any[]>([])
    const [ Cuploads , setCUploads ] = useState<any[]>([])
    const [ signature , setSignature ] = useState<null | string>(null)
    const [ dev ] = useState<boolean>(process.env.REACT_APP_ENV !== 'production' ? true : false )
    const [ Euploads , setEuploads ] = useState<string>('')
    const [ errors , setErrors ] = useState<i_customer_errors>(empty_customer_errors)
    const [ customer , setCustomer ] = useState<i_complete_new_claim>(empty_new_claim)
    const [ otp , setOtp ] = useState<string>('')
    const [ valid , setValid ] = useState<any>({ valid : false , type : 'default'})
    const [ indicative_option , setIndicativeOption ] = useState<string>('1')

    const canvas_ref = useRef<SignatureCanvas>(null);
    const sub_title = { fontSize : 14 , fontWeight : 600 , color : '#595959' }
    const sub_text = { fontSize : 12 , color : '#595959' }
    const red = "#ed6168"
    const nxt_btn = { en : 'Next' , fr : 'Suivant'}
    const prev_btn = { en : 'Previous' , fr : 'Précédent'}
    const ignore_btn = { en : 'Ignore' , fr : 'Ignoré'}
    const submit_btn = { en : "Submit" , fr : "Soumettre"}
    const submit_text = { en: "Form is being processed...", fr: "Formulaire en traitement..." };
    const top_title = { en: "Information Gathering - Insurance Claim", fr: "Prise d'information - Réclamation Assurance" };
    const basic_title = { en: "Enter your personal information", fr: "Entrée vos informations personnelles" };
    const otp_error = { en: "Please provide an OTP", fr: "Veuillez fournir un OTP" };
    const otp_resend = { en: "Didn't receive the code?", fr: "Vous n'avez pas reçu le code ?" };
    const otp_resend2 = { en: "Resend the code", fr: "Renvoyé le code" };
    const phone_button = { en: "Send an SMS for confirmation", fr: "Envoyer un SMS pour confirmation" };
    const otp_button = { en: "Send the OTP for validation", fr: "Envoyer le OTP pour valider" };
    const documents_uploads = { en: "Please provide the required photo documents", fr: "Veuillez fournir les documents en photo requis" };
    const licence_title = { en: "Please provide a front photo of your license*", fr: "Veuillez fournir une photo de permis avant*" };
    const licence_title_behind = { en: "Please provide a back photo of your license*", fr: "Veuillez fournir une photo de permis arrière*" };
    const registration_title = { en: "Please provide a photo of your registration*", fr: "Veuillez fournir une photo de vos enregistrements*" };
    const insurance_title = { en: "Please provide a photo of your insurance certificate*", fr: "Veuillez fournir une photo de votre certificat d'assurance*" };
    const car_picture_title = { en: "Please provide up to 4 photos of the vehicle (Optional)", fr: "Veuillez fournir de(s) photo(s) du véhicule maximum 4 (Optionnel)" };
    const car_part_title = { en: "Please provide vehicle information", fr: "Veuillez fournir les informations du véhicule" };
    const insurance_part_title = { en: "Please provide insurance information", fr: "Veuillez fournir les informations d'assurance" };
    const review_part = { en: "Summary of the provided information", fr: "Récapitulatif des informations fournies" };
    const last_info_title = { en: "Please validate your mobile number via SMS", fr: "Veuillez valider votre numéro de téléphone mobile via un SMS" };
    const end_title = { en: 'Form Completed !', fr :'Formulaire Compléter !'}
    const top_text = { en: "Welcome to Carrosserie Auto Prestige! Please complete this form with your contact details, ID, and insurance information. This data will help us process your case quickly and securely.", fr: "Bienvenue chez Carrosserie Auto Prestige! Merci de remplir ce formulaire avec vos coordonnées, pièce d'identité et informations d'assurance. Ces données nous aideront à traiter votre dossier rapidement et en toute sécurité." };
    const file_qty_err = { en: "Please upload the required file!", fr: "Veuillez téléverser le fichier requis!" };
    const terms = { en: "Terms and Conditions *", fr: "Termes et Conditions *" };
    const terms_text = { en: "By completing this form, you agree that Carrosserie Auto Prestige collects, uses, and retains your personal information, including your contact details, ID, and insurance information, for the purpose of processing your service request. A photo is taken of you when submitting the documents.", fr: "En remplissant ce formulaire, vous acceptez que Carrosserie Auto Prestige recueille, utilise et conserve vos renseignements personnels, incluant vos coordonnées, pièce d'identité et informations d'assurance, dans le but de traiter votre demande de service. Une photo est prise de vous lors de l'envoi des documents." };
    const conf = { en: "Confidentiality:", fr: "Confidentialité :" };
    const conf_text = { en: "Your personal information will be handled confidentially and will only be shared with third parties necessary for the provision of our services (e.g., insurance companies). We are committed to complying with applicable privacy laws, including the Private Sector Personal Information Protection Act.", fr: "Vos informations personnelles seront traitées de manière confidentielle et ne seront partagées qu'avec des tiers nécessaires à la prestation de nos services (ex. : compagnies d'assurances). Nous nous engageons à respecter les lois applicables en matière de protection des renseignements personnels, incluant la Loi sur la protection des renseignements personnels dans le secteur privé." };
    const usage = { en: "Data Usage", fr: "Utilisation des données" };
    const usage_text = { en: "The information provided will be used to open and manage your file, contact you, and facilitate the handling of claims and repairs. We only collect the necessary data and do not use it for any other purpose without your consent.", fr: "Les informations fournies seront utilisées pour ouvrir et gérer votre dossier, vous contacter, et faciliter la gestion des réclamations et réparations. Nous ne collectons que les données nécessaires et ne les utilisons à aucune autre fin sans votre consentement." };
    const rights = { en: "Right of Access and Rectification:", fr: "Droit d'accès et de rectification :" };
    const rights_text = { en: "In accordance with the law, you have the right to consult, correct, or delete your personal information by contacting us at: info@carrosserieautoprestige.com. We reserve the right to refuse modification or deletion of certain information for legal or administrative reasons.", fr: "Conformément à la loi, vous avez le droit de consulter, corriger ou supprimer vos informations personnelles en nous contactant à l'adresse : info@carrosserieautoprestige.com. Nous nous réservons le droit de refuser la modification ou suppression de certains renseignements pour des raisons légales ou administratives." };
    const security = { en: "Data Security:", fr: "Sécurité des données :" };
    const security_text = { en: "We implement appropriate security measures to protect your data from loss, unauthorized access, or any misuse.", fr: "Nous mettons en place des mesures de sécurité adaptées pour protéger vos données contre les pertes, les accès non autorisés, ou toute utilisation abusive." };
    const final_text = { en: "By submitting this form, you accept these terms and consent to the processing of your information by Carrosserie Auto Prestige.", fr: "En soumettant ce formulaire, vous acceptez ces conditions et consentez au traitement de vos informations par Carrosserie Auto Prestige." };
    const receive_text = { en: "We have received your request!", fr: "Nous avons bien reçu votre demande !" };
    const final_receive_text = { en: "Thank you for your trust. You will receive an email or a call soon regarding the next steps.", fr: "Nous vous remercions de votre confiance, Vous recevrez prochainement un e-mail ou un appel pour la suite des démarches." };
    const footer_text = { en: "Do not submit confidential information such as credit card details, mobile phone and ATM PIN codes, account passwords, etc.", fr: "Ne soumettez pas d'informations confidentielles telles que des détails de carte de crédit, des codes PIN de téléphone mobile et de distributeur automatique, des mots de passe de compte, etc." };
    const sign_title = { en: "Your electronic signature", fr: "Votre signature électronique" };
    const sign_rec = { en: "Once signed, please save the signature.", fr: "Une fois le pavé signé, veuillez enregistrer la signature." };
    const sign_proof = { en: "By signing, I authorize Carrosserie Auto Prestige to carry out the repairs according to the insurance estimate.", fr: "En signant, j'autorise Carrosserie Auto Prestige à effectuer la réparation selon l'estimation de l'assurance." };
    const sign_tip = { en: "Please sign in the box below to confirm that the information you have provided is valid and accurate.", fr: "Veuillez signer la boîte ci-dessous pour valider que les informations que vous avez fournies sont valides et exactes." };
    const contact_insurance = { en: "Have you contacted your insurer?", fr: "Avez-vous contacté votre assureur ?" };
    const info_comp = { en: "Additional Information", fr: "Informations complémentaires" };
    const info_contract = { en: "Your contract information", fr: "Informations de votre contrat" };
    const info_car = { en: "Vehicle Information", fr: "Informations du véhicule" };
    const info_adress = { en: "Address linked to your case", fr: "Adresse à votre dossier" };
    const info_perso = { en: "Personal Information", fr: "Informations personnelles" };
    const info_contact = { en: "Contact Information", fr: "Informations pour vous joindre" };
    const subfinal_text = { en: "You can now submit your request.", fr: "Vous pouvez maintenant soumettre votre demande." };
    const phone_valid = { en: "You will be able to validate the phone at another time.", fr: "Vous pourrez valider le téléphone à un autre moment." };
    const phone_ok = { en: "The phone has been validated.", fr: "Le téléphone a été validé." };
    const ignore_text = { en: "Skip validation", fr: "Ignorer la validation" };

    const email_text = { en: "Email*", fr: "Courriel*"}
    const phone_text = { en : "Phone*", fr : "Téléphone*"}
    const phone_sec_text = { en : "Secondary Phone*" , fr : "Téléphone Secondaire*"}
    const first_name_text = { en : "First Name*" , fr : "Prénom*"}
    const last_name_text = { en : 'Last Name*' , fr : "Nom*"}
    const unit_number_text = { en : 'Unit Number' , fr : "Numéro d'unité*"}
    const door_number_text = { en : 'Door Number*' , fr : 'Numéro de porte*'}
    const street_text = { en : 'Street*' , fr : 'Rue*'}
    const city_text = { en : "City*" , fr : 'Ville*'}
    const province_text = { en : "Province*" , fr : 'Province*' }
    const postal_code_text = { en : 'Postal Code*' , fr : 'Code Postal*'}

    const c_make_text = { en : 'Make*' , fr : 'Marque*'}
    const c_model_text = { en : 'Model*' , fr : 'Modèle*'}
    const c_option_text = { en : 'Trim*' , fr : "Option*"}
    const c_color_text = { en : 'Color*' , fr : 'Color*' }
    const c_kilo_text = { en : 'Kilometers*' , fr : 'Kilomètrage*'}
    const c_vin_info = { en : "The VIN (Vehicle Identification Number) is a unique alphanumeric code used to identify all cars worldwide. You can find it on your registration document." , fr : "Le numéro de VIN d'un véhicule est un code alphanumérique unique servant à identifier toutes les voitures à travers le monde , vous pouvez l'obtenir sur votre papier d'enregistrement"}
    const c_year_text = { en : 'Year*' , fr : 'Année*' }

    const a_comp_text = { en : "Insurance Company Name*" , fr : "Nom de la compagnie d'assurance*"}
    const a_pol_text = { en : "Insurance Police Number*" , fr : "Numéro de police d'assurance*"}
    const a_date_text = { en : 'Crash Date' , fr : "Date de l'accident"}
    const a_yes_text = { en : 'Yes' , fr :'Oui' }
    const a_no_text = { en : 'No' , fr : 'Non' }
    const dunno_text = { en : "I don't know", fr : "Je ne sais pas"}
    const a_claim_number = { en : 'Claim Number' , fr : 'Numéro de réclamation'}
    const a_courtier_name = { en : 'Courtier Name', fr : 'Nom du courtier' }

      
    const M : boolean = props.mobile
    const L : 'fr' | 'en' = props.language
    const handleStep = (type : 'prev' | 'next') : void => {
        var new_step : number = step
        new_step = type === 'prev' ? new_step - 1 : new_step + 1
        if(step > 1 && step < 10){
            const files = step === 3 ? Luploads : step === 4 ? L2uploads : step === 5 ? Ruploads : step === 6 ? Auploads : step === 7 ? Cuploads : []
            if((step === 3 || step  === 4 || step === 5 || step === 6 || step === 7) && type === 'next'){
                if(files.length === 0 && step !== 7) {
                    setEuploads(file_qty_err[L])
                    return
                }
            }else{
                if(type === 'next'){
                    const [ valid , field , msg ] = m_validate_public_claim( customer , step , L)
                    if(!valid){
                        if(step === 2) setErrors({ ...errors , [msg] : field[L]})
                        if(step === 8) setErrors({ ...errors , car : { ...errors.car , [msg] : field[L] } })
                        if(step === 9) setErrors({ ...errors , insurance : { ...errors.insurance , [msg] : field[L] } })
                        return
                    }
                }
            }
        }
        if(step === 11 && type === 'next'){
            handleSubmit()
        }else{
            setStep(new_step)
        }
    }
    const handleSubmit = async() => {
        const complete_form = new FormData()
        setLoading(true)
        const reformated_customer = { ...customer, signature : signature , claim_type : valid.type }
        complete_form.append('customer' , JSON.stringify(reformated_customer))
        complete_form.append('valid' , valid.type)
        complete_form.append('session_uuid' , customer.session_uuid)

        Cuploads.forEach((file, index) => {
            complete_form.append(`car_file_${index + 1}`, file);
        });
        Auploads.forEach((file, index) => {
            complete_form.append(`insurance_file_${index + 1}`, file);
        });
        Ruploads.forEach((file, index) => {
            complete_form.append(`registration_file_${index + 1}`, file);
        });
        Luploads.forEach((file, index) => {
            complete_form.append(`license_front_file_${index + 1}`, file);
        });
        L2uploads.forEach((file, index) => {
            complete_form.append(`license_back_file_${index + 1}`, file);
        });
        const res = await f_fetch_multiform('/public/claim', 'POST' , complete_form)
        setLoading(false)
        if(res.type === 'Success') setStep(12)
        setApiError({ open : true, promise : res})
    }
    const handleField = (e :  ChangeEvent<HTMLInputElement>) : void => {
        const { name , value } = e.target
        var custom_value = value
        var custom_name = name
        if(name.includes('phone')) custom_value = formatPhoneNumber(value)
        if(name.includes('car')){
            custom_name = name.replace('car_','')
            setCustomer({...customer, car : { ...customer.car , [custom_name] : value}  })
            return
        }
        if(name.includes('insurance')){
            custom_name = name.replace('insurance_','')
            setCustomer({...customer, insurance : {...customer.insurance , [custom_name] : value}  })
            return
        }
        setCustomer({...customer, [custom_name] : custom_value })
    }

    const handleSmsEvent = async() : Promise<void> => {
        const check_phone = m_validate_phone(customer.phone_one)
        if(check_phone){
            const timenow : number = Math.floor(Date.now() / 1000)
            setLoading(true)
            const body = { phone : indicative_option + customer.phone_one , timestamp : timenow , terms : 'accepted'}
            const res = await f_fetch('/plivo/sms' , 'POST' , true , body )
            setLoading(false)
            if(res.type === 'Success'){
                setSubStep(2)
                setCustomer({...customer , session_uuid : res.data })
            }else{
                setValid({ valid : true , type : 'failed'})
            }
            
            setApiError({ open : true, promise : res})
        }else{
            setErrors({...errors, phone_one : 'Numéro de téléphone invalide'})
        }
    }
    const handleOtpEvent = async() : Promise<void> => {
        const timenow : number = Math.floor(Date.now() / 1000)
        const body = { phone : customer.phone_one , timestamp : timenow , otp : otp , session_uuid : customer.session_uuid }
        if(otp.length === 0) {
            setOtpErr(otp_error[L])
            return
        }
        setLoading(true)
        const res = await f_fetch('/plivo/otp' , 'POST' , true , body )
        setLoading(false)
        if(res.type === 'Success'){
            setValid({valid : true , type : 'api validated'})
        }else{
            setValid({valid : false , type : 'api invalid'})
        }
        
        setApiError({ open : true, promise : res})
    }
    const handleOtp = (e :  ChangeEvent<HTMLInputElement>) : void => {
        const { value } = e.target
        if(value.length <= 6){
            setOtp(value)
        }
    }
    const handleDate = (date : Dayjs | null) : void => {
        if(date !== null)  setCustomer({...customer , event_date : date.valueOf()})
    }
    const returnTitle = () : string => {
        if(step === 1){
            return basic_title[L]
        } else if(step === 2){
            return basic_title[L]
        } 
        else if(step === 3 || step === 4 || step === 5 || step == 6 || step === 7 ){
            return documents_uploads[L]
        } else if(step === 8){
            return car_part_title[L]
        }
        else if(step === 9){
            return insurance_part_title[L]
        }
        else if(step === 10){
            return review_part[L]
        }else if(step === 11){
            return last_info_title[L]
        }else{
            return end_title[L]
        }
    }
    const alert_props : i_alert_props = {
        event : api_errors,
        handleClose : () => setApiError({ open : false, promise : f_empty_promise() }),
        type : 'simple'
    }
    const uploads_props : i_file_uploads_props = {
        uploads : step === 3 ? Luploads : step === 4 ? L2uploads : step === 5 ? Ruploads : step === 6 ? Auploads : Cuploads,
        setUploads : step === 3 ? setLUploads : step === 4 ? setL2Uploads : step === 5 ? setRUploads : step === 6 ? setAUploads : setCUploads,
        assets : [],
        setAssets : () => {},
        Euploads : Euploads,
        setEuploads : setEuploads,
        type : 'claim',
        path : '/webapi/uploads/newclaim/',
        title : step === 3 ? licence_title[L] : step === 4 ? licence_title_behind[L] : step === 5 ? registration_title[L] : step === 6 ? insurance_title[L] : car_picture_title[L],
        setApiError : setApiError,
        max : step === 7 ? 4 : 1,
        box_width : '100%',
        mobile : props.mobile,
        file_type : 'photo',
        language: L
    }
    useEffect(() => {
        if(step === 2 && sub_step === 2){
            if(otp.length > 0) setOtpErr('')
        }
    },[otp])
    const handleSave = (signature: string) => {
        setSignature(signature)
    };
    const handleClearSign = () => {
        setSignature(null)
    }
    useEffect(() => {
        if(step === 2){
            if(errors.email !== '' && m_validate_email(customer.email)) setErrors({...errors, email :''})
            if(errors.phone_one !== '' && m_validate_phone(customer.phone_one)) setErrors({...errors, phone_one : ''})
            if(errors.phone_two !== '' && m_validate_opt_phone(customer.phone_two)) setErrors({...errors, phone_two : ''})
            if(errors.first_name !== '' && m_validate_empty_fields(customer.first_name)) setErrors({...errors, first_name : ""})
            if(errors.last_name !== '' && m_validate_empty_fields(customer.last_name)) setErrors({...errors, last_name : ""})
            if(errors.door_number !== '' && m_validate_empty_fields(customer.door_number)) setErrors({...errors , door_number : ""})
            if(errors.street !== '' && m_validate_empty_fields(customer.street)) setErrors({...errors , street : ""})
            if(errors.city !== '' && m_validate_empty_fields(customer.city)) setErrors({...errors , city : ""})
            if(errors.zip !== '' && m_validate_zip_code(customer.zip)) setErrors({...errors , zip : ""})
        }
        if(step === 8){
            if(errors.car.make !== '' && m_validate_empty_fields(customer.car.make)) setErrors({...errors , car : { ...errors.car , make : ''}})
            if(errors.car.model !== '' && m_validate_empty_fields(customer.car.model)) setErrors({...errors , car : { ...errors.car , model : ''}})
            if(errors.car.year !== '' && m_validate_empty_fields(customer.car.year.toString())) setErrors({...errors , car : { ...errors.car , year : ''}})
            if(errors.car.color !== '' && m_validate_empty_fields(customer.car.color)) setErrors({...errors , car : { ...errors.car , color : ''}})
            if(errors.car.kilo !== '' && m_validate_empty_fields(customer.car.kilo)) setErrors({...errors , car : { ...errors.car , kilo : ''}})
            if(errors.car.trim !== '' && m_validate_empty_fields(customer.car.trim)) setErrors({...errors , car : { ...errors.car , trim : ''}})
            if(errors.car.vin !== '' && m_validate_empty_fields(customer.car.vin)) setErrors({...errors , car : { ...errors.car , vin : '' }})
        }
        if(step === 9){
            if(errors.insurance.company_name !== '' && m_validate_empty_fields(customer.insurance.company_name)) setErrors({...errors , insurance : { ...errors.insurance , company_name : ''}})
            if(errors.insurance.no_police !== '' && m_validate_empty_fields(customer.insurance.no_police)) setErrors({...errors , insurance : { ...errors.insurance , no_police : ''}})
        }
    },[customer , step , errors])
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if(step === 10){
            if(canvas_ref.current !== null && signature !== null )canvas_ref.current.fromDataURL(signature);
        }
    }, [step]);
                                                                  
    return(
        <Box  sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' , justifyContent : 'center' }}>
            <Box sx={{flex : 1 , maxWidth : '1800px' , margin: '0 auto' }} mb={2}>
                {step === 1 && <Typography onClick={() => props.setLanguage(L === 'en' ? 'fr' : 'en')} sx={{ textAlign : 'center' , fontSize : 14 , textDecoration : 'underline' , mt : 1 , cursor : 'pointer'}}>{props.language === 'fr' ? 'English' : 'Français'}</Typography> }
                <Box sx={{ backgroundImage : 'url(./images/bg_claim.jpg)',   backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', }}>
                    <Grid container p={ M ? 1 : 2}>
                        {step === 1 &&
                            <Grid item lg={5} md={6} xs={12} p={2} sx={{ display : 'flex' , justifyContent : 'center'}}>
                                <Box component={'img'} sx={{ width : '100%' , maxHeight : M ? '60vh' : '100vh' , maxWidth : '650px'}} src={'./images/claim_banner.jpg'}></Box>
                            </Grid>
                        }
                        <Grid item lg={step > 1 ? 12 : 7} md={step > 1 ? 12 :6} xs={12} mt={M ? 1.5 : 3} sx={{display : 'flex' , justifyContent : 'center' ,}}>
                            <Box sx={{ maxWidth : step > 1 ? '1000px' : '800px'}}>
                                <Box mt={M ? 0.5 : 3} >
                                    {step !== 1 && <Typography sx={{ color : red , fontSize : M ? 20 : 28 , textAlign : 'center'}} mb={2}>{returnTitle()} - { step - 1 }</Typography> }
                                    <Box sx={{}}>
                                        {step === 1 &&
                                            <Box>
                                                <Typography sx={{ color : red , fontSize : M ? 24 : 28}} mb={2}>{top_title[L]}</Typography>
                                                <Typography sx={{ fontSize : M ? 16 : 20 , color : '#595959' }} mb={3}>{top_text[L]}</Typography>
                                                <Typography sx={{ fontSize : M ? 20 : 24}} mb={2}>{terms[L]}</Typography>
                                                <Typography sx={sub_title}>{terms[L]}</Typography>
                                                <Typography sx={sub_text} mb={2}> {terms_text[L]}</Typography>
                                                <Typography sx={sub_title}>{conf[L]}</Typography>
                                                <Typography sx={sub_text} mb={2}>{conf_text[L]}</Typography>
                                                <Typography sx={sub_title}>{usage[L]}</Typography>
                                                <Typography sx={sub_text} mb={2}>{usage_text[L]}</Typography>
                                                <Typography sx={sub_title}>{rights[L]}</Typography>
                                                <Typography sx={sub_text} mb={2}>{rights_text[L]}</Typography>
                                                <Typography sx={sub_title}>{security[L]}</Typography>
                                                <Typography sx={sub_text} mb={2}>{security_text[L]}</Typography>
                                                <hr style={{ color : red , width : '100%' , marginBottom : '2vh'}}></hr>
                                                <Box sx={{ display : 'flex' , marginBottom : '3vh'}}>
                                                    <Typography sx={[{ fontSize : M ? 16 : 20 , marginLeft : 3 , alignSelf : 'center' , textDecoration : 'underline' }  ]} >{final_text[L]}</Typography>
                                                </Box>
                                            </Box>
                                        }
                                        { step === 2 &&
                                            <Box p={M ? 1 : 2} sx={clean_container_borders}>
                                                <Box>
                                                    <Typography sx={item_title}>{info_contact[L]}</Typography>
                                                </Box>
                                                <Grid container sx={item_center}> 
                                                    <Grid item xs={12} sm={6} md={4} mb={1} sx={item_center}>
                                                        <TextField label={email_text[L]} helperText={errors.email} error={errors.email !== ''} sx={M ? full_width : input_item} value={customer.email} name={'email'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={1} sx={item_center}>
                                                        <TextField label={phone_text[L]} helperText={errors.phone_one} error={errors.phone_one !== ''} sx={M ? full_width : input_item} value={customer.phone_one} name={'phone_one'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={1} sx={item_center}>
                                                        <TextField label={phone_sec_text[L]} helperText={errors.phone_two} error={errors.phone_two !== ''} sx={M ? full_width : input_item} value={customer.phone_two} name={'phone_two'} onChange={handleField}></TextField>
                                                    </Grid>
                                                </Grid>
                                                <Box>
                                                    <Typography sx={item_title}>{info_perso[L]}</Typography>
                                                </Box>
                                                <Grid container sx={{ display : 'flex'}}>
                                                    <Grid item xs={12} sm={6} md={4} sx={item_center}>
                                                        <TextField label={first_name_text[L]} sx={M ? full_width : input_item} helperText={errors.first_name} error={errors.first_name !== ''} value={customer.first_name} name={'first_name'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} sx={item_center}>
                                                        <TextField label={last_name_text[L]} sx={M ? full_width : input_item} helperText={errors.last_name} error={errors.last_name !== ''} value={customer.last_name} name={'last_name'} onChange={handleField}></TextField>
                                                    </Grid>
                                                </Grid>
                                        
                                                
                                                <Grid container sx={item_center}> 
                                                    <Grid item xs={12}>
                                                        <Typography sx={item_title}>{info_adress[L]}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={1} sx={item_center}>
                                                        <TextField label={unit_number_text[L]} helperText={errors.unit_number} error={errors.unit_number !== ''} sx={M ? full_width : input_item} value={customer.unit_number} name={'unit_number'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={1} sx={item_center}>
                                                        <TextField label={door_number_text[L]} helperText={errors.door_number} error={errors.door_number !== ''} sx={M ? full_width : input_item} value={customer.door_number} name={'door_number'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={1}  sx={item_center}>
                                                        <TextField label={street_text[L]} helperText={errors.street} error={errors.street !== ''} sx={M ? full_width : input_item} value={customer.street} name={'street'} onChange={handleField}></TextField>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={4} mb={1}  sx={item_center}>
                                                        <TextField label={city_text[L]} helperText={errors.city} error={errors.city !== ''} sx={M ? full_width : input_item} value={customer.city} name={'city'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={1} sx={item_center}>
                                                    <TextField sx={M ? full_width : input_item}  name={province_text[L]} value={customer.state} onChange={handleField}
                                                    helperText={errors.state} error={errors.state!== ''}select> 
                                                        {provinces.map((provinces , index ) => ( <MenuItem key={index} value={provinces.value}>{provinces.value}</MenuItem>  ))}
                                                    </TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={1} sx={item_center}>
                                                        <TextField label={postal_code_text[L]} helperText={errors.zip} error={errors.zip !== ''} sx={M ? full_width : input_item} value={customer.zip} name={'zip'} onChange={handleField}></TextField>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }
                                        {(step === 3 || step === 4 || step === 5 || step === 6 || step === 7) && 
                                            <Box>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <UploadBoxV2 {...uploads_props} />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }
                                        { step === 8 &&
                                            <Box  p={2} sx={clean_container_borders}>
                                                <Grid container> 
                                                    <Grid item xs={12}>
                                                        <Typography sx={item_title}>{info_car[L]}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={2} sx={item_center}>
                                                        <TextField label={c_make_text[L]} helperText={errors.car.make} error={errors.car.make!== ''}  value={customer.car.make} sx={M ? full_width : input_item}name={'car_make'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={2} sx={item_center}>
                                                        <TextField label={c_model_text[L]} helperText={errors.car.model} error={errors.car.model!== ''} value={customer.car.model}sx={M ? full_width : input_item} name={'car_model'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={2}  sx={item_center}>
                                                        <TextField label={c_option_text[L]} helperText={errors.car.trim} error={errors.car.trim!== ''} value={customer.car.trim} sx={M ? full_width : input_item} name={'car_trim'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={2}  sx={item_center}>
                                                        <TextField label={c_color_text[L]} helperText={errors.car.color} error={errors.car.color!== ''} sx={M ? full_width : input_item} value={customer.car.color} name={'car_color'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={2}  sx={item_center}>
                                                        <TextField type={'number'} helperText={errors.car.kilo} error={errors.car.kilo!== ''} label={c_kilo_text[L]} sx={M ? full_width : input_item} value={customer.car.kilo} name={'car_kilo'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={2} sx={item_center}>
                                                        <TextField type={'number'} helperText={errors.car.year} error={errors.car.year!== ''} label={c_year_text[L]} value={customer.car.year} sx={M ? full_width : input_item} name={'car_year'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} mb={2} sx={item_center}>
                                                        <Tooltip title={c_vin_info[L]}>
                                                            <TextField label="VIN*" helperText={errors.car.vin} error={errors.car.vin !== ''} value={customer.car.vin} sx={M ? full_width : input_item} name="car_vin" onChange={handleField} InputProps={{ endAdornment: ( <InputAdornment position="end"> <IconButton> <HelpOutlineIcon /> </IconButton> </InputAdornment> ), }}/>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }
                                        { step === 9 && 
                                            <Box  p={2} sx={clean_container_borders}>
                                                <Grid container> 
                                                    <Grid item xs={12}>
                                                        <Typography sx={item_title}>{info_contract[L]}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} mb={2} sx={item_center}>
                                                        <TextField label={a_comp_text[L]} helperText={errors.insurance.company_name} error={errors.insurance.company_name !== ''} value={customer.insurance.company_name} sx={full_width}name={'insurance_company_name'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} mb={2} sx={item_center}>
                                                        <TextField label={a_pol_text[L]} helperText={errors.insurance.no_police} error={errors.insurance.no_police !== ''} value={customer.insurance.no_police}sx={full_width} name={'insurance_no_police'} onChange={handleField}></TextField>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography sx={item_title}>{info_comp[L]}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} mb={2}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker sx={{}} label={a_date_text[L]}  value={dayjs(customer.event_date)} onChange={(newValue : Dayjs | null) => handleDate(newValue)}/>
                                                    </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} mb={2}>
                                                    <FormControl>
                                                        <FormLabel>{contact_insurance[L]} </FormLabel>
                                                            <RadioGroup value={customer.open} name={'open'} onChange={handleField} sx={{ display : 'flex'}}>
                                                                <FormControlLabel value="yes" control={<Radio />} label={a_yes_text[L]} />
                                                                <FormControlLabel value="no" control={<Radio />} label={a_no_text[L]} />
                                                                <FormControlLabel value="dunno" control={<Radio />} label={dunno_text[L]} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    {customer.open === 'yes' &&
                                                        <>
                                                            <Grid item xs={12} sm={6} md={4} mb={2}  sx={item_center}>
                                                                <TextField label={a_claim_number[L]} sx={M ? full_width : input_item} value={customer.claim_number} name={'claim_number'} onChange={handleField}></TextField>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} mb={2}  sx={item_center}>
                                                                <TextField label={a_courtier_name[L]} sx={M ? full_width : input_item} value={customer.insurance.courtier} name={'insurance_courtier'} onChange={handleField}></TextField>
                                                            </Grid>
                                                        </>
                                                    }
            
                                                </Grid>
                                            </Box>
                                        }
                                        { step === 10 &&
                                            <Box>
                                                <Box mt={3}>
                                                    {signature === null &&
                                                        <Box>
                                                            <Typography sx={M ?  {...item_title , fontSize : 14} : item_title}>{sign_tip[L]}</Typography>
                                                            <Typography sx={M ?  {...item_title , fontSize : 14}  : item_title}>{sign_proof[L]}</Typography>

                                                            <Typography sx={{ textAlign : 'center' , textDecoration : 'underline' , fontSize : 14}}>{sign_rec[L]}</Typography>
                                                        </Box>
                                                    }
                                                    
                                                    {signature!== null &&
                                                        <Box>
                                                            <Typography sx={item_title}>{sign_title[L]}</Typography>
                                                        </Box>
                                                    }
                                                    <SignaturePad ref={canvas_ref} language={L} mobile={M} setApiError={setApiError} onSave={handleSave} onClear={handleClearSign} sign={signature} />
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                        {step < 10 &&
                                            <Box mt={2}  sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                {step !== 1 && <Button sx={{ fontSize : 16 , marginRight : 2 , backgroundColor : 'white' , color : 'black'}}  variant="contained" onClick={() => handleStep('prev')}>{prev_btn[L]}</Button> }
                                                <Button sx={{ fontSize : 16}} disabled={signature === null && step === 10 ? true : false} variant="contained" onClick={() => handleStep('next')}>{step === 11 ? submit_btn[L] : ( step === 7 && Cuploads.length === 0 ) ? ignore_btn[L] : nxt_btn[L] }</Button>
                                            </Box>
                                        }
                                        {step === 10 &&
                                            <Box mt={2}  sx={{ display : 'flex' , justifyContent : 'center'}}>
                                              <Button sx={{ fontSize : 16 , marginRight : 2 , backgroundColor : 'white' , color : 'black'}}  variant="contained" onClick={() => handleStep('prev')}>{prev_btn[L]}</Button>
                                              {signature !== null &&
                                                <Button sx={{ fontSize : 16}} disabled={signature === null && step === 10 ? true : false} variant="contained" onClick={() => handleStep('next')}>{nxt_btn[L]}</Button>
                                              }
                                            </Box>
                                        }
                                        { step === 11 &&
                                            <Box mt={M ? 1 : 5}>
                                                {!valid.valid ?
                                                    <Box>
                                                        {( sub_step === 1  ) ?
                                                            <Box mt={10} sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                                <TextField select value={indicative_option} onChange={(e) => setIndicativeOption(e.target.value)}>
                                                                    <MenuItem value={"1"} title={'Canada'}>+1</MenuItem>
                                                                </TextField>
                                                                <TextField label={phone_text[L]} error={errors.phone_one !== '' ? true : false} helperText={errors.phone_one} type="text" value={customer.phone_one} name={'phone_one'} onChange={handleField}/>
                                                            </Box> 
                                                            :
                                                            <Box mt={10} sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                                <TextField label={'OTP'} sx={{ fontSize :22}} value={otp} error={otp_err !== '' ? true : false} helperText={otp_err} onChange={handleOtp}></TextField>
                                                            </Box>
                                                        }
                                                        {sub_step === 2 &&
                                                            <Box mt={1} sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                                <Typography sx={{fontSize : 12}} mr={1}>{otp_resend[L]}</Typography>
                                                                <Typography sx={{ fontSize : 12, textDecoration : 'underline' , cursor : 'pointer'}} onClick={() => handleSmsEvent()}>{otp_resend2[L]}</Typography>
                                                            </Box>
                                                        }
                                                            <Box mt={2}  sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                                <Button variant="contained" onClick={() => sub_step === 1 ? handleSmsEvent() : handleOtpEvent()}>{ sub_step === 1 ? phone_button[L] : otp_button[L]}</Button>
                                                            </Box>
                                                        {valid.type === 'api invalid' &&
                                                            <Box  mt={2}  sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                                <Button onClick={() => setValid({ valid : true , type : 'skip'})}>{ignore_text[L]}</Button>
                                                            </Box>
                                                        }
                                                    </Box>
                                                    :
                                                    <Box>
                                                        {loading ?
                                                            <Box>
                                                                <Typography sx={{ fontSize : 20 , textAlign : 'center'}}>{submit_text[L]}</Typography>
                                                            </Box>
                                                        :
                                                            <Box>
                                                                {valid.type === 'api validated' &&
                                                                    <Typography sx={{ fontSize : 20 , textAlign : 'center'}}>{phone_ok[L]}</Typography>
                                                                }
                                                                {valid.type === 'failed' || valid.type === 'skip' &&
                                                                    <Typography sx={{ fontSize : 20 , textAlign : 'center'}}>{phone_valid[L]}</Typography>
                                                                }
                                                                <Typography mt={4} sx={{ fontSize : 24 , textAlign : 'center' , textDecoration : 'underline'}}>{subfinal_text[L]}</Typography>
                                                            </Box>
                                                        }
                                                    </Box>
                                                }
                                                <>
                                                    { loading ?
                                                        <Box sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                            <CircularProgress />
                                                        </Box>
                                                        :
                                                        <Box mt={10}  sx={{ display : 'flex' , justifyContent : 'center'}}>
                                                            <Button sx={{ fontSize : 18 , marginRight : 2 , backgroundColor : 'white' , color : 'black'}} variant="contained" onClick={() => handleStep('prev')}>{prev_btn[L]}</Button>
                                                            {valid.valid &&
                                                                <Button sx={{ fontSize : 18}} variant="contained" onClick={() => handleStep('next')}>{submit_btn[L]}</Button>
                                                            }
                                                        </Box>
                                                    }
                                                </>
                                            </Box>     
                                        }
                                        {step === 12 &&
                                            <Box>
                                                 <Box sx={{ marginTop : 20 }}>
                                                    <Typography sx={{ fontSize : M ? 16 : 18 , textAlign : 'center' , textDecoration : 'underline' }}>{receive_text[L]}</Typography>
                                                    <Typography mt={3} sx={{ fontSize : M ? 16 : 18 , textAlign : 'center' }}>{final_receive_text[L]}</Typography>
                                                 </Box>
                                             </Box>
                                        }
                                    
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <AlertDialog {...alert_props} />
            <Box sx={{ width: '100%', borderTop: '1px solid gray', padding: '12px', backgroundColor: '#595959', minHeight: '4vh', marginTop: 'auto', }}>
                <Typography sx={{ color : 'white' , fontSize : M ? 10 : 14 , textAlign : 'center'}}>{footer_text[L]}</Typography>
            </Box>
        </Box>
    )
}


export default NewClaim