/*
 * Date: 2025
 * Description: Landing page when a user is validated !
 * Author: Philippe Leroux @ SKITSC
 *
 */

//Modules
import React, { ReactElement, useContext , useState } from "react";
import { Box , Container , Typography , Paper , Grid2, Accordion , AccordionSummary , AccordionDetails , IconButton  } from "@mui/material";
import { Link } from "react-router-dom"

//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface";
import { MainContext } from "../context/context";

//Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Dashboard = ( props : i_initial_props ) : ReactElement => {
    const { redirect_url } = useContext(MainContext)
    const [ expanded, setExpanded ] = useState<string | false>(false);
    const patchNotes = [
        { version: '0.2.1', date: '2025-02-27', changes: ['Added secure endpoint for pdf document download.' , 'Added logics to front-end in order to keep the redirect url' , 'Added a new middleware to catch if the query file is valid' , 'Always more..'] },
    ];
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    console.log(redirect_url)
    return (
        <Box>

        <Container sx={{ marginTop : '2vh'}}>
            {redirect_url !== '' &&
                <Box sx={{ padding : 1, border : '2px solid red' , display : 'flex' , justifyContent : 'center'}}>
                    <Box sx={{ textAlign : 'center'}} component={Link} to={`${redirect_url}`}>Click here to redirect to your document</Box>
                </Box>
            }
            <Typography sx={{ textAlign : 'center'}}> Dashboard </Typography>
            <Grid2 container>
                <Grid2 size={{ xs : 3}}>

                </Grid2>          
                <Grid2 size={{ xs : 6}}>
                    <Paper elevation={3} sx={{padding : '1em' , maxWidth : 1000 , display : 'block' , marginLeft : 'auto' , marginRight : 'auto' , marginTop : '3vh'}}>
                        {patchNotes.map((patch, index) => (
                                <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`} >
                                        <Typography sx={{ fontWeight: 'bold' }}>Version {patch.version} - {patch.date}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {patch.changes.map((change, change_index) => (
                                            <Typography key={change_index}>- {change}</Typography>
                                        ))}
                                    </AccordionDetails>
                            </Accordion>
                        ))}
                    </Paper>
                </Grid2>
                <Grid2 size={{ xs : 3}}>

                </Grid2>
            </Grid2>
        </Container>
        </Box>
    );
}

export default Dashboard;