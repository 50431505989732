  /*
 * Date: 2024
 * Description: Styles for forms
 * Author: Philippe Leroux @SKITSC
 */

const upload_box_style = {display: 'flex', flexWrap: 'wrap', gap: '1em' , justifyContent : 'center' }

const FormStyles = { textfield: { '& p':{ color:'red', }, "&.MuiFormHelperText-root.Mui-error" :{ color: "red" }, }, errorfield: { '& p':{ color:'red', }, "&.MuiFormHelperText-root.Mui-error" :{ color: "red" }, marginTop : '1vh', }, errors: { color: "red", textAlign: "center", }, label : { marginTop : "0.5vh" }, inline:{ display:"inline" }, input: { display: "none", }, };
const input_base = { pl : 0, minHeight: '60px', borderRadius: 10, paddingRight : 2 }

const button_center = { display : 'block', marginLeft : 'auto', marginRight : 'auto', fontWeight : 800 }
const sub_title = { fontWeight : 600 }
const bottom_line = { borderColor : '#EAECF0' , opacity : 0.4 }
const item_title = { textDecoration : 'underline', fontSize : 18 , fontWeight : '700' , margin : 2 }
const input_item = { width : '275px' }
const full_width = { width : '100%' }
const input_item_sm = { width : '230px' }
const clean_container_borders = {  border : '1px solid #EAECF0' , borderTopLeftRadius:'8px' , borderTopRightRadius: '8px'  }
const item_center = { display : 'flex' , justifyContent : 'center' }
const top_table_bar_style = { paddingLeft : '12px' , alignSelf : 'center' , fontWeight : 600 , paddingRight : '6px' }
const container_grid ={ border : '1px solid #EAECF0' , borderTopLeftRadius:'8px' , borderTopRightRadius: '8px' , maxWidth : '1400px' ,  marginLeft : 'auto' , marginRight: 'auto'}
export { FormStyles , input_base , button_center , sub_title , bottom_line , top_table_bar_style , upload_box_style , container_grid , item_center , item_title , input_item , input_item_sm , full_width , clean_container_borders}