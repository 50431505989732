
import { Box, Typography , Button } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useSearchParams , useNavigate, Link } from "react-router-dom"

//Components
import LoadingFull from "../components/loading/loading.full"

import { f_fetch } from "../api/fetch"
import { delay } from "../utils/utility"
import { MainContext } from "../context/context"

const Document = () => {
    const [ valid , setValid ] = useState<boolean | null>(null)
    const { setCurrentRedirectUrl } = useContext(MainContext)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [searchParams] = useSearchParams();
    const file: string = searchParams.get("file") ?? "empty";
    const key = searchParams.get("key");
    const navigate = useNavigate();
    useEffect(() => {
        const handleVerification = async() => {
            setLoading(true)
            await delay(1000)
            const file = searchParams.get("file");
            const res = await f_fetch(`/document?key=${key}&file=${file}` , 'GET' , true , null)
            setLoading(false)
            if(res.type === 'Success'){
                setValid(true)
                return
            }
            if(res.type !== 'Unauthorized'){
                navigate("/404");
                return
            }
            if(res.type === 'Unauthorized'){
                setCurrentRedirectUrl(`/document?key=${key}&file=${file}`)
                setValid(false)
            }
        }
        handleVerification()
    },[searchParams , navigate , delay])

    return (
        <Box>
            { loading ? <LoadingFull text={"Retreiving documents..."} />
            :
                <Box sx={{ textAlign: "center", marginTop: "25vh" }}>
                    {valid ?
                        <Box sx={{ padding : 2 , fontSize : 20 }}>
                            <Box sx={{ marginBottom : '2vh'}}>Document Found !</Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = `${process.env.REACT_APP_API_URL}/webapi/uploads/public/claim/${key}/${file}`; 
                                    link.download = file;
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                }}
                            >
                                Download Pdf File
                            </Button>
                        </Box>
                        
                        :
                        <Box>
                            <Typography> Please login before viewing this document </Typography>
                            <Box component={Link} to={'/login'}>Login page</Box>
                        </Box>
                    }
                </Box>
             }
        </Box>
    )
}


export default Document